import React from 'react'

import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div
          className="full-width-image margin-top-0"
          style={{
            backgroundImage: `url('/img/blog-index.jpg')`,
          }}
        >
          <div
            style={{
              display: 'flex',
              lineHeight: '1.5',
              flexDirection: 'column',
              border: '1px solid #ccc',
              padding: '20px 24px',
              textTransform: 'uppercase',
              textAlign: 'center',
              color: '#fff',
              letterSpacing: '4px'
            }}
          >
            <h1
              className="is-size-1"
            >
              Historias recientes
          </h1>
          </div>
        </div>
        <section className="section">
          <div className="container">
            <div className="content">
              <BlogRoll />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
